import React, { FC } from 'react';
import { SubscriptionsSearch, SubscriptionsTable } from 'features/subscriptions/hoc';
import { useSubscriptionsTranslations } from 'features/subscriptions/hooks';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const SubscriptionsPage: FC = () => {
  const { t } = useSubscriptionsTranslations();
  const navigate = useNavigate();

  return (
    <Container>
      <div className='page-header'>
        <h2 className='page-header-title'>{t('subscriptions')}</h2>
      </div>
      <Row className='mb-3'>
        <Col lg='auto'>
          <SubscriptionsSearch />
        </Col>
        <Col className='d-flex justify-content-end'>
          <Button onClick={() => navigate('add')} size='sm' variant='primary'>
            {t('add_subscription')}
          </Button>
        </Col>
      </Row>
      <Card>
        <SubscriptionsTable />
      </Card>
    </Container>
  );
};

export default SubscriptionsPage;
