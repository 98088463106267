import { DataResponse, ListRequest, ListResponse } from '../../types';
import { PromoCode, PromoCodesFilter, SavePromoCodeRequest } from './types';
import httpClient from '../../httpClient';

const baseUrl = '/admin/promo-codes';

export const getPromoCodes = async (request: ListRequest<PromoCodesFilter>) => {
  const params = { ...request.filter, ...request.sort, ...request.paging };
  return httpClient.get<ListRequest<PromoCodesFilter>, ListResponse<PromoCode>>(baseUrl, {
    params,
  });
};

export const getPromoCode = async (id: number) => {
  return httpClient.get<void, DataResponse<PromoCode>>(`${baseUrl}/${id}`);
};

export const addPromoCode = async (request: SavePromoCodeRequest) => {
  return httpClient.post<SavePromoCodeRequest, DataResponse<PromoCode>>(baseUrl, request);
};

export const editPromoCode = async (id: number, request: SavePromoCodeRequest) => {
  return httpClient.put<SavePromoCodeRequest, DataResponse<PromoCode>>(`${baseUrl}/${id}`, request);
};
