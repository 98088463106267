import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { SubscriptionForm } from 'features/subscriptions/hoc';

const SubscriptionAddPage: FC = () => {
  return (
    <Container>
      <SubscriptionForm />
    </Container>
  );
};

export default SubscriptionAddPage;
