import { Article as ApiArticle, SaveArticleRequest } from 'api/clients/articles/types';
import { Article, ArticleFormData } from './types';
import moment from 'moment';
import { formatDate, parseDate } from 'utils/date';

export const mapArticle = (article: ApiArticle): Article => ({
  ...article,
  published: moment(article.published).format('YYYY-MM-DD'),
  notified: article.notified ? moment(article.notified).format('YYYY-MM-DD HH:mm:ss') : null,
  updated: article.updated ? moment(article.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(article.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapArticleFormData = (article: Article): ArticleFormData => ({
  title: article.title,
  description: article.description,
  url: article.url,
  published: parseDate(article.published),
  author: article.author,
  enabled: article.enabled,
  addedPhoto: null,
});

export const mapSaveArticleRequest = (formData: ArticleFormData): SaveArticleRequest => ({
  ...formData,
  published: formatDate(formData.published),
  addedPhoto: formData.addedPhoto?.value ?? null,
});
