import { Subscription as ApiSubscription } from 'api/clients/subscriptions/types';
import { Subscription, SubscriptionFormData } from './types';
import moment from 'moment';

export const mapSubscription = (subscription: ApiSubscription): Subscription => ({
  ...subscription,
  updated: subscription.updated ? moment(subscription.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(subscription.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapSubscriptionFormData = (subscription: Subscription): SubscriptionFormData => ({
  sku: subscription.sku,
  enabled: subscription.enabled,
  title: subscription.title,
  description: subscription.description,
  policy: subscription.policy,
  durationInMonths: subscription.durationInMonths,
  price: subscription.price,
  currency: subscription.currency,
});
