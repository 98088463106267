import { Control, Controller } from 'react-hook-form';
import { MagazineFormData } from '../types';
import React, { FC } from 'react';
import { useMagazinesTranslations } from '../hooks';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { CurrencySelect, DatePicker, FormGroup } from 'components';

interface Props {
  className?: string;
  control: Control<MagazineFormData>;
}

const PricingForm: FC<Props> = (props) => {
  const { control, className } = props;
  const { t } = useMagazinesTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('pricing')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue={false}
              control={control}
              name='enabled'
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <FormGroup
                  row
                  help={t('determine_if_magazine_is_available_to_purchase')}
                  error={error?.message}
                  label={t('enabled')}
                >
                  <Form.Switch isInvalid={!!error} checked={value} {...rest} />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col>
            <Controller
              control={control}
              name='published'
              render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('published')}>
                  <DatePicker value={value} {...rest} />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='link'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('link')}>
                  <Form.Control isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <hr className='my-4' />
        <Row>
          <Col>
            <Controller
              defaultValue={0}
              control={control}
              name='price'
              render={({ field: priceField, fieldState: { error } }) => (
                <FormGroup
                  help={t('price_must_be_same_as_in_iaphub')}
                  error={error?.message}
                  label={t('price')}
                >
                  <InputGroup>
                    <Form.Control isInvalid={!!error} {...priceField} type='number' />
                    <Controller
                      defaultValue='eur'
                      control={control}
                      name='currency'
                      render={({ field: { value, onChange } }) => (
                        <CurrencySelect value={value} onChange={onChange} />
                      )}
                    />
                  </InputGroup>
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PricingForm;
