import { RootState } from 'core/redux-store';
import { PromoCode } from './types';
import { PromoCodesFilter } from 'api/clients/promo-codes/types';
import { Paging, Sort } from 'api/types';

export const selectPromoCodes = (state: RootState): PromoCode[] => {
  return state.promoCodes.promoCodes;
};

export const selectPromoCodesCount = (state: RootState): number => {
  return state.promoCodes.promoCodesCount;
};

export const selectPromoCodesLastUpdated = (state: RootState): number => {
  return state.promoCodes.lastUpdated;
};

export const selectFilter = (state: RootState): PromoCodesFilter => {
  return state.promoCodes.filter;
};

export const selectPaging = (state: RootState): Paging => {
  return state.promoCodes.paging;
};

export const selectSort = (state: RootState): Sort => {
  return state.promoCodes.sort;
};

export const selectPromoCode = (state: RootState): PromoCode | null => {
  return state.promoCodes.promoCode;
};
