import React, { FC, useCallback, useMemo } from 'react';
import { useAppSelector } from 'core/redux-store';
import { selectFilter, selectPaging, selectSort } from '../selectors';
import { useUsers, useUsersTranslations } from '../hooks';
import { BoldCell, DataTable, UpdatedCell } from 'components';
import { Column } from 'react-table';
import { User } from '../types';
import { useDispatch } from 'react-redux';
import { Paging, Sort } from 'api/types';
import { setPaging, setSort } from '../slice';
import { IsSubscriberCell, RolesCell, StatusCell } from '../components';

const UsersTable: FC = () => {
  const { t } = useUsersTranslations();
  const dispatch = useDispatch();

  const filter = useAppSelector(selectFilter);
  const sort = useAppSelector(selectSort);
  const paging = useAppSelector(selectPaging);

  const { usersCount, users } = useUsers({ filter, sort, paging });

  const handleOnPagingChange = useCallback(
    (newPaging: Paging) => dispatch(setPaging({ paging: newPaging })),
    [dispatch]
  );

  const handleOnSortChange = useCallback(
    (newSort: Sort) => dispatch(setSort({ sort: newSort })),
    [dispatch]
  );

  const columns = useMemo<Column<User>[]>(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('email'),
        Cell: BoldCell,
        accessor: 'email',
      },
      {
        Header: t('status'),
        Cell: StatusCell,
        accessor: 'status',
      },
      {
        Header: t('is_subscriber'),
        Cell: IsSubscriberCell,
        disableSortBy: true,
        accessor: 'isActiveSubscriber',
      },
      {
        Header: t('roles'),
        Cell: RolesCell,
        accessor: 'roles',
      },
      {
        Header: t('updated'),
        Cell: UpdatedCell,
        accessor: 'updated',
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
    ],
    [t]
  );

  return (
    <DataTable
      data={users}
      columns={columns}
      total={usersCount}
      onPagingChange={handleOnPagingChange}
      onSortChange={handleOnSortChange}
      paging={paging}
      sort={sort}
    />
  );
};

export default UsersTable;
