import { DataResponse, ListRequest, ListResponse } from '../../types';
import httpClient from '../../httpClient';
import { Article, ArticlesFilter, SaveArticleRequest } from './types';

const baseUrl = '/admin/articles';

export const getArticles = async (request: ListRequest<ArticlesFilter>) => {
  const params = { ...request.filter, ...request.sort, ...request.paging };
  return httpClient.get<ListRequest<ArticlesFilter>, ListResponse<Article>>(baseUrl, { params });
};

export const getArticle = async (id: number) => {
  return httpClient.get<void, DataResponse<Article>>(`${baseUrl}/${id}`);
};

export const addArticle = async (request: SaveArticleRequest) => {
  return httpClient.post<SaveArticleRequest, DataResponse<Article>>(baseUrl, request);
};

export const editArticle = async (id: number, request: SaveArticleRequest) => {
  return httpClient.put<SaveArticleRequest, DataResponse<Article>>(`${baseUrl}/${id}`, request);
};

export const notifyArticle = async (id: number) => {
  return httpClient.put<void, DataResponse<Article>>(`${baseUrl}/${id}`);
};
