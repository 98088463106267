import { RootState } from 'core/redux-store';
import { Magazine } from './types';
import { MagazinesFilter } from 'api/clients/magazines/types';
import { Paging, Sort } from 'api/types';

export const selectMagazines = (state: RootState): Magazine[] => {
  return state.magazines.magazines;
};

export const selectMagazinesCount = (state: RootState): number => {
  return state.magazines.magazinesCount;
};

export const selectMagazinesLastUpdated = (state: RootState): number => {
  return state.magazines.lastUpdated;
};

export const selectFilter = (state: RootState): MagazinesFilter => {
  return state.magazines.filter;
};

export const selectPaging = (state: RootState): Paging => {
  return state.magazines.paging;
};

export const selectSort = (state: RootState): Sort => {
  return state.magazines.sort;
};

export const selectMagazine = (state: RootState): Magazine | null => {
  return state.magazines.magazine;
};
