import React, { ChangeEvent, FC } from 'react';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { selectFilter } from '../selectors';
import { setFilter } from '../slice';
import { DebounceInput } from 'react-debounce-input';
import { usePromoCodesTranslations } from '../hooks';
import { Search } from 'react-bootstrap-icons';

interface Props {
  className?: string;
}

const PromoCodesSearch: FC<Props> = (props) => {
  const { className } = props;

  const { t } = usePromoCodesTranslations();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    dispatch(setFilter({ filter: { ...filter, keyword } }));
  };

  return (
    <div className={classNames('input-group input-group-merge input-group-flush', className)}>
      <div className='input-group-prepend input-group-text'>
        <Search />
      </div>
      <DebounceInput
        debounceTimeout={500}
        value={filter.keyword ?? ''}
        onChange={handleOnChange}
        type='search'
        className='form-control'
        placeholder={t('search_promo_codes')}
      />
    </div>
  );
};

export default PromoCodesSearch;
