import React, { FC } from 'react';
import { CellProps } from 'react-table';
import { User } from '../types';
import { useUsersTranslations } from '../hooks';

const RolesCell: FC<CellProps<User, string[]>> = (props) => {
  const { value: roles } = props;
  const { t } = useUsersTranslations();

  return <>{roles.map((role) => t(role)).join(', ')}</>;
};

export default RolesCell;
