import { Control, Controller } from 'react-hook-form';
import { ArticleFormData } from '../types';
import React, { FC } from 'react';
import { useArticlesTranslations } from '../hooks';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FormGroup } from 'components';

interface Props {
  control: Control<ArticleFormData>;
  className?: string;
}

const ArticleInformationForm: FC<Props> = (props) => {
  const { className, control } = props;
  const { t } = useArticlesTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('article_information')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='title'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('title')}>
                  <Form.Control isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='url'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('link')}>
                  <Form.Control isInvalid={!!error} {...field} type='url' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='description'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('description')}>
                  <Form.Control rows={3} isInvalid={!!error} {...field} as='textarea' type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ArticleInformationForm;
