import React, { FC } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useArticleForm, useArticlesTranslations, useNotifyArticle } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { Article } from '../types';
import { ArticleInformationForm, MetaForm, MediaForm } from '../components';
import { showSuccessToast } from 'core/toast';

interface Props {
  article?: Article;
}

const ArticleForm: FC<Props> = (props) => {
  const { article } = props;
  const { t } = useArticlesTranslations();
  const navigate = useNavigate();
  const { notify, isLoading: isNotifying } = useNotifyArticle();

  const onSuccess = () => {
    showSuccessToast(t('article_is_successfully_saved'));
    navigate('/articles');
  };

  const { control, handleSubmit, save, isLoading } = useArticleForm({ article, onSuccess });

  return (
    <>
      <div className='page-header'>
        <Row className='align-items-center'>
          <Col>
            <h2 className='page-header-title'>
              {!!article ? t('edit_article') : t('add_article')}
            </h2>
          </Col>
          <Col lg='auto'>
            <Button
              size='sm'
              className='me-2'
              onClick={() => navigate('/articles')}
              disabled={isLoading}
              variant='white'
            >
              {t('back_to_articles_list')}
            </Button>
            {!!article && (
              <Button
                size='sm'
                className='me-2'
                onClick={() => notify(article.id)}
                disabled={isLoading || isNotifying || !!article.notified}
                variant='white'
              >
                {article.notified ? t('notified_date', { date: article.notified }) : t('notify')}
              </Button>
            )}
            <Button size='sm' onClick={handleSubmit(save)} disabled={isLoading} variant='primary'>
              {t('save_article')}
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={8}>
          <ArticleInformationForm className='mb-4' control={control} />
          <MediaForm control={control} article={article} />
        </Col>
        <Col lg={4}>
          <MetaForm control={control} />
        </Col>
      </Row>
    </>
  );
};

export default ArticleForm;
