import { Magazine, MagazineFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { MagazinesClient } from 'api/clients';
import { useEffect } from 'react';
import { mapMagazineFormData, mapSaveMagazineRequest } from '../map';
import { updateMagazines } from '../slice';
import { useAppDispatch } from 'core/redux-store';

const addedPhotoShape = yup.object().shape({
  name: yup.string().required(),
  size: yup.number().required(),
  value: yup.string().required(),
});

const magazineShape = yup.object().shape({
  enabled: yup.boolean().required(),
  title: yup.string().required(),
  sku: yup.string().required(),
  description: yup.string().required(),
  price: yup.number().required(),
  currency: yup.string().required(),
  published: yup.date().required(),
  link: yup.string().url().required(),
  addedPhotos: yup.array().of(addedPhotoShape).required(),
});

const getSchema = () => magazineShape.required();

interface Props {
  magazine?: Magazine;
  onSuccess: () => void;
}

const useMagazineForm = (props: Props) => {
  const { magazine, onSuccess } = props;
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset } = useForm<MagazineFormData>({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    if (!magazine) return;
    reset(mapMagazineFormData(magazine));
  }, [magazine, reset]);

  const { mutateAsync, isLoading } = useMutation(
    async (formData: MagazineFormData) => {
      const request = mapSaveMagazineRequest(formData);
      if (!magazine) await MagazinesClient.addMagazine(request);
      else await MagazinesClient.editMagazine(magazine.id, request);
    },
    {
      onSuccess: () => {
        dispatch(updateMagazines());
      },
    }
  );

  const save = async (formData: MagazineFormData) => {
    await mutateAsync(formData);
    onSuccess();
  };

  return { handleSubmit, save, control, isLoading };
};

export default useMagazineForm;
