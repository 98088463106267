import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { selectArticle } from '../selectors';
import { useQuery } from 'react-query';
import { ArticlesClient } from 'api/clients';
import { setArticle } from '../slice';
import { mapArticle } from '../map';

export const getQueryKey = (id: number) => {
  return ['article', id];
};

const useArticle = (id: number) => {
  const dispatch = useAppDispatch();
  const article = useAppSelector(selectArticle);

  const getArticleFn = () => ArticlesClient.getArticle(id);
  const { isLoading } = useQuery(getQueryKey(id), getArticleFn, {
    onSuccess: (response) => {
      const data = mapArticle(response.data);
      dispatch(setArticle({ data }));
    },
  });

  return { isLoading, article };
};

export default useArticle;
