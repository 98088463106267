import { RootState } from 'core/redux-store';
import { User } from './types';
import { UsersFilter } from 'api/clients/users/types';
import { Paging, Sort } from 'api/types';

export const selectUsers = (state: RootState): User[] => {
  return state.users.users;
};

export const selectUsersCount = (state: RootState): number => {
  return state.users.usersCount;
};

export const selectUsersLastUpdated = (state: RootState): number => {
  return state.users.lastUpdated;
};

export const selectFilter = (state: RootState): UsersFilter => {
  return state.users.filter;
};

export const selectPaging = (state: RootState): Paging => {
  return state.users.paging;
};

export const selectSort = (state: RootState): Sort => {
  return state.users.sort;
};
