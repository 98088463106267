import { Subscription, SubscriptionFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { SubscriptionsClient } from 'api/clients';
import { useEffect } from 'react';
import { mapSubscriptionFormData } from '../map';

const subscriptionShape = yup.object().shape({
  sku: yup.string().required(),
  enabled: yup.boolean().required(),
  title: yup.string().required(),
  policy: yup.string().required(),
  description: yup.string().required(),
  durationInMonths: yup.number().integer().required(),
  price: yup.number().required(),
  currency: yup.string().required(),
});

const getSchema = () => subscriptionShape.required();

interface Props {
  subscription?: Subscription;
  onSuccess: () => void;
}

const useSubscriptionForm = (props: Props) => {
  const { subscription, onSuccess } = props;

  const { handleSubmit, control, reset } = useForm<SubscriptionFormData>({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    if (!subscription) return;
    reset(mapSubscriptionFormData(subscription));
  }, [subscription, reset]);

  const { mutateAsync, isLoading } = useMutation(async (request: SubscriptionFormData) => {
    if (!subscription) await SubscriptionsClient.addSubscription(request);
    else await SubscriptionsClient.editSubscription(subscription.id, request);
  });

  const save = async (formData: SubscriptionFormData) => {
    await mutateAsync(formData);
    onSuccess();
  };

  return { handleSubmit, save, control, isLoading };
};

export default useSubscriptionForm;
