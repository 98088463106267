import React, { FC } from 'react';
import { Button, Card } from 'react-bootstrap';
import { sizeToKb } from 'utils/file';
import { useTranslation } from 'react-i18next';

interface Props {
  src: string;
  name: string;
  size: number;
  onRemove?: () => void;
  className?: string;
}

const FileCard: FC<Props> = (props) => {
  const { src, name, size, className, onRemove } = props;
  const { t } = useTranslation('common');

  return (
    <Card className={className}>
      <Card.Body className='d-flex align-items-center'>
        <div className='avatar me-4'>
          <img className='avatar-img' src={src} alt={name} />
        </div>
        <div className='d-flex flex-column justify-content-center flex-grow-1 me-2'>
          <h5 className='mb-0'>{name}</h5>
          <span className='d-block fs-6 text-body'>{sizeToKb(size)} KB</span>
        </div>
        {!!onRemove && (
          <Button onClick={onRemove} variant='white' size='sm'>
            {t('remove')}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default FileCard;
