import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataResponse, ListResponse, Paging, Sort } from 'api/types';
import { Article } from './types';
import { ArticlesFilter } from 'api/clients/articles/types';
import { logout } from 'features/auth/slice';

interface State {
  articles: Article[];
  articlesCount: number;
  lastUpdated: number;
  filter: ArticlesFilter;
  sort: Sort;
  paging: Paging;
  article: Article | null;
}

const initialState: State = {
  articles: [],
  articlesCount: 0,
  lastUpdated: Date.now(),
  filter: {},
  sort: { sortBy: 'created', sortDirection: 'desc' },
  paging: { limit: 10, offset: 0 },
  article: null,
};

const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    setPaging: (state, action: PayloadAction<{ paging: Paging }>) => {
      state.paging = action.payload.paging;
    },
    setSort: (state, action: PayloadAction<{ sort: Sort }>) => {
      state.sort = action.payload.sort;
      state.paging = { ...state.paging, offset: 0 };
    },
    setFilter: (state, action: PayloadAction<{ filter: ArticlesFilter }>) => {
      state.filter = action.payload.filter;
      state.paging = { ...state.paging, offset: 0 };
    },
    setArticles: (state, action: PayloadAction<ListResponse<Article>>) => {
      state.articles = action.payload.data;
      state.articlesCount = action.payload.total;
    },
    updateArticles: (state) => {
      state.lastUpdated = Date.now();
    },
    setArticle: (state, action: PayloadAction<DataResponse<Article>>) => {
      state.article = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setArticle, updateArticles, setArticles, setSort, setPaging, setFilter } =
  articlesSlice.actions;

export const { reducer } = articlesSlice;
