import { SubscriptionsFilter } from 'api/clients/subscriptions/types';
import { Paging, Sort } from 'api/types';
import { useAppDispatch, useAppSelector } from 'core/redux-store';
import {
  selectSubscriptions,
  selectSubscriptionsCount,
  selectSubscriptionsLastUpdated,
} from '../selectors';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { SubscriptionsClient } from 'api/clients';
import { setSubscriptions } from '../slice';
import { mapSubscription } from '../map';

export const getQueryKey = (filter: SubscriptionsFilter, sort: Sort, paging: Paging) => {
  return ['subscriptions', filter, sort, paging];
};

interface Props {
  filter: SubscriptionsFilter;
  sort: Sort;
  paging: Paging;
}

const useSubscriptions = (props: Props) => {
  const { filter, sort, paging } = props;

  const dispatch = useAppDispatch();
  const lastUpdated = useAppSelector(selectSubscriptionsLastUpdated);
  const subscriptions = useAppSelector(selectSubscriptions);
  const subscriptionsCount = useAppSelector(selectSubscriptionsCount);

  const getSubscriptionsFn = () => SubscriptionsClient.getSubscriptions({ filter, paging, sort });
  const { isLoading, refetch } = useQuery(getQueryKey(filter, sort, paging), getSubscriptionsFn, {
    onSuccess: (response) => {
      const data = response.data.map(mapSubscription);
      dispatch(setSubscriptions({ ...response, data }));
    },
  });

  useEffect(() => {
    refetch();
  }, [lastUpdated, refetch]);

  return { isLoading, subscriptions, subscriptionsCount };
};

export default useSubscriptions;
