import React, { FC, useCallback, useMemo } from 'react';
import { useAppSelector } from 'core/redux-store';
import { selectFilter, selectPaging, selectSort } from '../selectors';
import { usePromoCodes, usePromoCodesTranslations } from '../hooks';
import { BoldCell, DataTable, UpdatedCell } from 'components';
import { Column } from 'react-table';
import { PromoCode } from '../types';
import { useDispatch } from 'react-redux';
import { Paging, Sort } from 'api/types';
import { setPaging, setSort } from '../slice';
import { StatusCell, ActionsCell } from '../components';

const PromoCodesTable: FC = () => {
  const { t } = usePromoCodesTranslations();
  const dispatch = useDispatch();

  const filter = useAppSelector(selectFilter);
  const sort = useAppSelector(selectSort);
  const paging = useAppSelector(selectPaging);

  const { promoCodesCount, promoCodes } = usePromoCodes({ filter, sort, paging });

  const handleOnPagingChange = useCallback(
    (newPaging: Paging) => dispatch(setPaging({ paging: newPaging })),
    [dispatch]
  );

  const handleOnSortChange = useCallback(
    (newSort: Sort) => dispatch(setSort({ sort: newSort })),
    [dispatch]
  );

  const columns = useMemo<Column<PromoCode>[]>(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('code'),
        Cell: BoldCell,
        accessor: 'code',
      },
      {
        Header: t('number_of_usage'),
        accessor: 'numberOfUsage',
      },
      {
        Header: t('number_of_codes'),
        accessor: 'numberOfCodes',
      },
      {
        Header: t('status'),
        Cell: StatusCell,
        accessor: 'enabled',
      },
      {
        Header: t('start_date'),
        accessor: 'startDate',
      },
      {
        Header: t('end_date'),
        accessor: 'endDate',
      },
      {
        Header: t('updated'),
        Cell: UpdatedCell,
        accessor: 'updated',
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
      {
        Header: t('actions'),
        id: 'actions',
        disableSortBy: true,
        Cell: ActionsCell,
      },
    ],
    [t]
  );

  return (
    <DataTable
      data={promoCodes}
      columns={columns}
      total={promoCodesCount}
      onPagingChange={handleOnPagingChange}
      onSortChange={handleOnSortChange}
      paging={paging}
      sort={sort}
    />
  );
};

export default PromoCodesTable;
