import { FC, useEffect, useMemo } from 'react';
import { Column, SortingRule, useSortBy, useTable } from 'react-table';
import classNames from 'classnames';
import { Sort } from '../api/types';

export type TableRowType = any;

export interface TableProps {
  className?: string;
  data: TableRowType[];
  columns: Column<TableRowType>[];
  onRowClick?: (row: TableRowType) => void;
  sort?: Sort;
  onSortChange?: (sort: Sort) => void;
}

const Table: FC<TableProps> = (props) => {
  const { className, columns, data, onRowClick, onSortChange, sort } = props;

  const initialSortBy = useMemo<SortingRule<object>[]>(() => {
    if (!sort) return [];
    return [{ id: sort.sortBy, desc: sort.sortDirection === 'desc' }];
  }, [sort]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      autoResetSortBy: false,
      disableMultiSort: true,
      disableSortRemove: true,
      initialState: { sortBy: initialSortBy },
    },
    useSortBy
  );

  useEffect(() => {
    if (!onSortChange || sortBy.length === 0) return;
    const [newSort] = sortBy;
    const sortDirection = newSort.desc ? 'desc' : 'asc';
    onSortChange({ sortDirection, sortBy: newSort.id });
  }, [onSortChange, sortBy]);

  return (
    <div className={classNames('table-responsive datatable-custom', className)}>
      <table
        className='table table-borderless table-thead-bordered table-nowrap table-align-middle card-table no-footer'
        {...getTableProps()}
      >
        <thead className='thead-light'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={classNames({
                    sorting: column.canSort,
                    sorting_desc: column.isSorted && !column.isSortedDesc,
                    sorting_asc: column.isSorted && column.isSortedDesc,
                  })}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr onClick={() => onRowClick?.(row.original)} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
