import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { selectMagazine } from '../selectors';
import { useQuery } from 'react-query';
import { MagazinesClient } from 'api/clients';
import { setMagazine } from '../slice';
import { mapMagazine } from '../map';

export const getQueryKey = (id: number) => {
  return ['magazine', id];
};

const useMagazine = (id: number) => {
  const dispatch = useAppDispatch();
  const magazine = useAppSelector(selectMagazine);

  const getMagazineFn = () => MagazinesClient.getMagazine(id);
  const { isLoading } = useQuery(getQueryKey(id), getMagazineFn, {
    onSuccess: (response) => {
      const data = mapMagazine(response.data);
      dispatch(setMagazine({ data }));
    },
  });

  return { isLoading, magazine };
};

export default useMagazine;
