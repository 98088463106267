import React, { ChangeEvent, FC } from 'react';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { selectFilter } from '../selectors';
import { setFilter } from '../slice';
import { DebounceInput } from 'react-debounce-input';
import { useUsersTranslations } from '../hooks';
import { Search } from 'react-bootstrap-icons';
import { Form } from 'react-bootstrap';

interface Props {
  className?: string;
}

const UsersSearch: FC<Props> = (props) => {
  const { className } = props;

  const { t } = useUsersTranslations();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    dispatch(setFilter({ filter: { ...filter, keyword } }));
  };

  const handleOnIsActiveSubscriberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isActiveSubscriber = event.target.checked ? true : undefined;
    dispatch(setFilter({ filter: { ...filter, isActiveSubscriber } }));
  };

  return (
    <div className={classNames('d-flex align-items-center', className)}>
      <div className='input-group input-group-merge input-group-flush'>
        <div className='input-group-prepend input-group-text'>
          <Search />
        </div>
        <DebounceInput
          debounceTimeout={500}
          value={filter.keyword ?? ''}
          onChange={handleOnChange}
          type='search'
          className='form-control'
          placeholder={t('search_users')}
        />
      </div>
      <Form.Check
        className='ms-4 text-nowrap'
        onChange={handleOnIsActiveSubscriberChange}
        checked={filter.isActiveSubscriber}
        type='checkbox'
        label={t('is_active_subscriber')}
      />
    </div>
  );
};

export default UsersSearch;
