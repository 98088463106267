import React, { FC } from 'react';
import { FileCard } from 'components';
import { MagazinePhoto } from '../types';
import { getAbsoluteFilePath } from '../utils';

interface Props {
  onRemovedPhotosChange: (removedPhotos: number[]) => void;
  removedPhotos: number[];
  photos: MagazinePhoto[];
  className?: string;
}

const RemovedPhotosControl: FC<Props> = (props) => {
  const { onRemovedPhotosChange, photos, removedPhotos, className } = props;
  const filteredPhotos = photos.filter((photo) => !removedPhotos.includes(photo.id));

  const handleOnRemove = (id: number) => {
    removedPhotos.push(id);
    onRemovedPhotosChange(removedPhotos);
  };

  return (
    <div className={className}>
      {filteredPhotos.map((photo) => (
        <FileCard
          className='mb-2'
          key={photo.id}
          name={photo.name}
          src={getAbsoluteFilePath(photo.name)}
          size={photo.size}
          onRemove={() => handleOnRemove(photo.id)}
        />
      ))}
    </div>
  );
};

export default RemovedPhotosControl;
