import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useMagazine } from 'features/magazines/hooks';
import { MagazineForm } from 'features/magazines/hoc';
import { useParams } from 'react-router-dom';

const MagazineEditPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { magazine, isLoading } = useMagazine(id);

  return <Container>{!!magazine && !isLoading && <MagazineForm magazine={magazine} />}</Container>;
};

export default MagazineEditPage;
