import { useMutation } from 'react-query';
import { MagazinesClient } from 'api/clients';
import { mapMagazine } from '../map';
import { setMagazine, updateMagazines } from '../slice';
import { useAppDispatch } from 'core/redux-store';

const useNotifyMagazine = () => {
  const dispatch = useAppDispatch();

  const { mutateAsync, isLoading } = useMutation(
    async (id: number) => MagazinesClient.notifyMagazine(id),
    {
      onSuccess: (response) => {
        const data = mapMagazine(response.data);
        dispatch(setMagazine({ data }));
        dispatch(updateMagazines());
      },
    }
  );

  const notify = (id: number) => mutateAsync(id);

  return { isLoading, notify };
};

export default useNotifyMagazine;
