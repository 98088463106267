import { UsersFilter } from 'api/clients/users/types';
import { Paging, Sort } from 'api/types';
import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { selectUsers, selectUsersCount, selectUsersLastUpdated } from '../selectors';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { UsersClient } from 'api/clients';
import { setUsers } from '../slice';
import { mapUser } from '../map';

export const getQueryKey = (filter: UsersFilter, sort: Sort, paging: Paging) => {
  return ['users', filter, sort, paging];
};

interface Props {
  filter: UsersFilter;
  sort: Sort;
  paging: Paging;
}

const useUsers = (props: Props) => {
  const { filter, sort, paging } = props;

  const dispatch = useAppDispatch();
  const lastUpdated = useAppSelector(selectUsersLastUpdated);
  const users = useAppSelector(selectUsers);
  const usersCount = useAppSelector(selectUsersCount);

  const getUsersFn = () => UsersClient.getUsers({ filter, paging, sort });
  const { isLoading, refetch } = useQuery(getQueryKey(filter, sort, paging), getUsersFn, {
    onSuccess: (response) => {
      const data = response.data.map(mapUser);
      dispatch(setUsers({ ...response, data }));
    },
  });

  useEffect(() => {
    refetch();
  }, [lastUpdated, refetch]);

  return { isLoading, users, usersCount };
};

export default useUsers;
