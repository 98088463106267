import { FC } from 'react';
import { CellProps } from 'react-table';
import { Article } from '../types';
import { getAbsoluteFilePath } from '../utils';

const TitleCell: FC<CellProps<Article, string>> = (props) => {
  const { value: title, row } = props;
  const photo = row.original.photo;

  return (
    <div className='d-flex align-items-center'>
      <div className='flex-shrink-0'>
        <div className='avatar'>
          <img className='avatar-img' src={getAbsoluteFilePath(photo.name)} alt={title} />
        </div>
      </div>
      <div className='flex-grow-1 ms-3'>
        <h5 className='text-inherit mb-0 break-word'>{title}</h5>
      </div>
    </div>
  );
};

export default TitleCell;
