import { PromoCodeFormData, PromoCode } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { PromoCodesClient } from 'api/clients';
import { useEffect } from 'react';
import { mapPromoCodeFormData, mapSavePromoCodeRequest } from '../map';

const promoCodeShape = yup.object().shape({
  enabled: yup.boolean().required(),
  code: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  numberOfCodes: yup.number().integer().required(),
  sku: yup.string().nullable(),
});

const getSchema = () => promoCodeShape.required();

interface Props {
  promoCode?: PromoCode;
  onSuccess: () => void;
}

const usePromoCodeForm = (props: Props) => {
  const { promoCode, onSuccess } = props;

  const { handleSubmit, control, reset } = useForm<PromoCodeFormData>({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    if (!promoCode) return;
    reset(mapPromoCodeFormData(promoCode));
  }, [promoCode, reset]);

  const { mutateAsync, isLoading } = useMutation(async (formData: PromoCodeFormData) => {
    const request = mapSavePromoCodeRequest(formData);
    if (!promoCode) await PromoCodesClient.addPromoCode(request);
    else await PromoCodesClient.editPromoCode(promoCode.id, request);
  });

  const save = async (formData: PromoCodeFormData) => {
    await mutateAsync(formData);
    onSuccess();
  };

  return { handleSubmit, save, control, isLoading };
};

export default usePromoCodeForm;
