import React, { FC, useMemo } from 'react';
import { LegendIndicatorVariant } from 'components/LegendIndicator';
import { LegendIndicator } from 'components';
import { CellProps } from 'react-table';
import { User } from '../types';
import { useUsersTranslations } from '../hooks';
import { UserStatus } from 'api/clients/users/types';

interface MemoValue {
  variant: LegendIndicatorVariant;
  label: string;
}

const StatusCell: FC<CellProps<User, UserStatus>> = (props) => {
  const { value: status } = props;
  const { t } = useUsersTranslations();

  const { variant, label } = useMemo<MemoValue>(
    () => ({
      variant: status === 'active' ? 'success' : 'secondary',
      label: status === 'active' ? t('active') : t('blocked'),
    }),
    [status, t]
  );

  return <LegendIndicator variant={variant} label={label} />;
};

export default StatusCell;
