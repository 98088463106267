import { RootState } from 'core/redux-store';
import { Subscription } from './types';
import { SubscriptionsFilter } from 'api/clients/subscriptions/types';
import { Paging, Sort } from 'api/types';

export const selectSubscriptions = (state: RootState): Subscription[] => {
  return state.subscriptions.subscriptions;
};

export const selectSubscriptionsCount = (state: RootState): number => {
  return state.subscriptions.subscriptionsCount;
};

export const selectSubscriptionsLastUpdated = (state: RootState): number => {
  return state.subscriptions.lastUpdated;
};

export const selectFilter = (state: RootState): SubscriptionsFilter => {
  return state.subscriptions.filter;
};

export const selectPaging = (state: RootState): Paging => {
  return state.subscriptions.paging;
};

export const selectSort = (state: RootState): Sort => {
  return state.subscriptions.sort;
};

export const selectSubscription = (state: RootState): Subscription | null => {
  return state.subscriptions.subscription;
};
