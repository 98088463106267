import { Control, Controller } from 'react-hook-form';
import { Magazine, MagazineFormData } from '../types';
import React, { FC } from 'react';
import { useMagazinesTranslations } from '../hooks';
import { Card } from 'react-bootstrap';
import { AddedPhotosControl, RemovedPhotosControl } from './index';

interface Props {
  className?: string;
  magazine?: Magazine;
  control: Control<MagazineFormData>;
}

const MediaForm: FC<Props> = (props) => {
  const { control, className, magazine } = props;
  const { t } = useMagazinesTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('media')}</h5>
      </Card.Header>
      <Card.Body>
        {!!magazine && (
          <Controller
            defaultValue={[]}
            control={control}
            name='removedPhotos'
            render={({ field: { onChange, value } }) => (
              <RemovedPhotosControl
                photos={magazine.photos}
                removedPhotos={value}
                onRemovedPhotosChange={onChange}
              />
            )}
          />
        )}
        <Controller
          defaultValue={[]}
          control={control}
          name='addedPhotos'
          render={({ field: { onChange, value } }) => (
            <AddedPhotosControl addedPhotos={value} onAddedPhotosChange={onChange} />
          )}
        />
      </Card.Body>
    </Card>
  );
};

export default MediaForm;
