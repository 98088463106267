import { FC } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import UserDropdown from './UserDropdown';
import { Link, NavLink } from 'react-router-dom';
import logo from 'assets/img/logo.png';
import { List, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const Header: FC = () => {
  const { t } = useTranslation('navigation');

  return (
    <Navbar as='header' expand='lg' className='navbar-bordered navbar-spacer-y-0 flex-lg-column'>
      <Navbar variant='dark' className='w-100 bg-dark py-2'>
        <Container>
          <div className='navbar-nav-wrap'>
            <Link to='/articles'>
              <Navbar.Brand>
                <img className='navbar-brand-logo' src={logo} alt="L'Officiel" />
              </Navbar.Brand>
            </Link>
            <div className='navbar-nav-wrap-content-end'>
              <ul className='navbar-nav'>
                <li className='nav-item'>
                  <UserDropdown />
                </li>
                <li className='nav-item'>
                  <button className='navbar-toggler collapsed' type='button'>
                    <span className='navbar-toggler-default'>
                      <List />
                    </span>
                    <span className='navbar-toggler-toggled'>
                      <X />
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </Navbar>
      <Container>
        <Navbar expand={false} className='navbar-collapse collapse'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <NavLink to='/articles' className='nav-link'>
                {t('articles')}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/magazines' className='nav-link'>
                {t('magazines')}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/users' className='nav-link'>
                {t('users')}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/promo-codes' className='nav-link'>
                {t('promo_codes')}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/subscriptions' className='nav-link'>
                {t('subscriptions')}
              </NavLink>
            </li>
          </ul>
        </Navbar>
      </Container>
    </Navbar>
  );
};

export default Header;
