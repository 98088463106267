import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useArticle } from 'features/articles/hooks';
import { ArticleForm } from 'features/articles/hoc';
import { useParams } from 'react-router-dom';

const ArticleAddPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { article, isLoading } = useArticle(id);

  return <Container>{!!article && !isLoading && <ArticleForm article={article} />}</Container>;
};

export default ArticleAddPage;
