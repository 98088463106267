import { ArticlesFilter } from 'api/clients/articles/types';
import { Paging, Sort } from 'api/types';
import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { selectArticles, selectArticlesCount, selectArticlesLastUpdated } from '../selectors';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { ArticlesClient } from 'api/clients';
import { setArticles } from '../slice';
import { mapArticle } from '../map';

export const getQueryKey = (filter: ArticlesFilter, sort: Sort, paging: Paging) => {
  return ['articles', filter, sort, paging];
};

interface Props {
  filter: ArticlesFilter;
  sort: Sort;
  paging: Paging;
}

const useArticles = (props: Props) => {
  const { filter, sort, paging } = props;

  const dispatch = useAppDispatch();
  const lastUpdated = useAppSelector(selectArticlesLastUpdated);
  const articles = useAppSelector(selectArticles);
  const articlesCount = useAppSelector(selectArticlesCount);

  const getArticlesFn = () => ArticlesClient.getArticles({ filter, paging, sort });
  const { isLoading, refetch } = useQuery(getQueryKey(filter, sort, paging), getArticlesFn, {
    onSuccess: (response) => {
      const data = response.data.map(mapArticle);
      dispatch(setArticles({ ...response, data }));
    },
  });

  useEffect(() => {
    refetch();
  }, [lastUpdated, refetch]);

  return { isLoading, articles, articlesCount };
};

export default useArticles;
