import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListResponse, Paging, Sort } from 'api/types';
import { User } from './types';
import { logout } from 'features/auth/slice';
import { UsersFilter } from 'api/clients/users/types';

interface State {
  users: User[];
  usersCount: number;
  lastUpdated: number;
  filter: UsersFilter;
  sort: Sort;
  paging: Paging;
}

const initialState: State = {
  users: [],
  usersCount: 0,
  lastUpdated: Date.now(),
  filter: {},
  sort: { sortBy: 'created', sortDirection: 'desc' },
  paging: { limit: 10, offset: 0 },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setPaging: (state, action: PayloadAction<{ paging: Paging }>) => {
      state.paging = action.payload.paging;
    },
    setSort: (state, action: PayloadAction<{ sort: Sort }>) => {
      state.sort = action.payload.sort;
      state.paging = { ...state.paging, offset: 0 };
    },
    setFilter: (state, action: PayloadAction<{ filter: UsersFilter }>) => {
      state.filter = action.payload.filter;
      state.paging = { ...state.paging, offset: 0 };
    },
    setUsers: (state, action: PayloadAction<ListResponse<User>>) => {
      state.users = action.payload.data;
      state.usersCount = action.payload.total;
    },
    updateUsers: (state) => {
      state.lastUpdated = Date.now();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setUsers, updateUsers, setSort, setPaging, setFilter } = usersSlice.actions;
export const { reducer } = usersSlice;
