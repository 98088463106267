import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { ArticleForm } from 'features/articles/hoc';

const ArticleAddPage: FC = () => {
  return (
    <Container>
      <ArticleForm />
    </Container>
  );
};

export default ArticleAddPage;
