import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataResponse, ListResponse, Paging, Sort } from 'api/types';
import { Magazine } from './types';
import { MagazinesFilter } from 'api/clients/magazines/types';
import { logout } from 'features/auth/slice';

interface State {
  magazines: Magazine[];
  magazinesCount: number;
  lastUpdated: number;
  filter: MagazinesFilter;
  sort: Sort;
  paging: Paging;
  magazine: Magazine | null;
}

const initialState: State = {
  magazines: [],
  magazinesCount: 0,
  lastUpdated: Date.now(),
  filter: {},
  sort: { sortBy: 'created', sortDirection: 'desc' },
  paging: { limit: 10, offset: 0 },
  magazine: null,
};

const magazinesSlice = createSlice({
  name: 'magazines',
  initialState,
  reducers: {
    setPaging: (state, action: PayloadAction<{ paging: Paging }>) => {
      state.paging = action.payload.paging;
    },
    setSort: (state, action: PayloadAction<{ sort: Sort }>) => {
      state.sort = action.payload.sort;
      state.paging = { ...state.paging, offset: 0 };
    },
    setFilter: (state, action: PayloadAction<{ filter: MagazinesFilter }>) => {
      state.filter = action.payload.filter;
      state.paging = { ...state.paging, offset: 0 };
    },
    setMagazines: (state, action: PayloadAction<ListResponse<Magazine>>) => {
      state.magazines = action.payload.data;
      state.magazinesCount = action.payload.total;
    },
    updateMagazines: (state) => {
      state.lastUpdated = Date.now();
    },
    setMagazine: (state, action: PayloadAction<DataResponse<Magazine>>) => {
      state.magazine = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setMagazines, updateMagazines, setMagazine, setSort, setPaging, setFilter } =
  magazinesSlice.actions;

export const { reducer } = magazinesSlice;
