import { ListRequest, ListResponse } from '../../types';
import { User, UsersFilter } from './types';
import httpClient from '../../httpClient';

const baseUrl = '/admin/users';

export const getUsers = async (request: ListRequest<UsersFilter>) => {
  const params = { ...request.filter, ...request.sort, ...request.paging };
  return httpClient.get<ListRequest<UsersFilter>, ListResponse<User>>(baseUrl, {
    params,
  });
};
