import React, { FC } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import currencies, { CurrencyCode } from 'constants/currencies';
import classNames from 'classnames';

interface Props {
  value: CurrencyCode;
  onChange: (value: CurrencyCode) => void;
  className?: string;
}

const CurrencySelect: FC<Props> = (props) => {
  const { value, onChange, className } = props;

  return (
    <DropdownButton
      className={classNames('no-arrow', className)}
      variant='white'
      title={currencies[value]}
    >
      {Object.entries(currencies).map(([code, label]) => (
        <Dropdown.Item
          active={code === value}
          onClick={() => onChange(code as CurrencyCode)}
          as='div'
          key={code}
        >
          {label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default CurrencySelect;
