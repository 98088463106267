import React, { FC } from 'react';
import { Base64File } from 'api/types';
import { FileCard, Dropzone } from 'components';

interface Props {
  onAddedPhotosChange: (addedPhotos: Base64File[]) => void;
  addedPhotos: Base64File[];
  className?: string;
}

const AddedPhotosControl: FC<Props> = (props) => {
  const { onAddedPhotosChange, addedPhotos, className } = props;

  const handleOnAdd = (photosToAdd: Base64File[]) => {
    onAddedPhotosChange([...addedPhotos, ...photosToAdd]);
  };

  const handleOnRemove = (indexToRemove: number) => {
    const filteredPhotos = addedPhotos.filter((_, index) => index !== indexToRemove);
    onAddedPhotosChange(filteredPhotos);
  };

  return (
    <div className={className}>
      {addedPhotos.map((addedPhoto, index) => (
        <FileCard
          className='mb-2'
          key={index}
          name={addedPhoto.name}
          size={addedPhoto.size}
          src={addedPhoto.value}
          onRemove={() => handleOnRemove(index)}
        />
      ))}
      <Dropzone onAdd={handleOnAdd} />
    </div>
  );
};

export default AddedPhotosControl;
