import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { ArticleFormData } from '../types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useArticlesTranslations } from '../hooks';
import { DatePicker, FormGroup } from 'components';

interface Props {
  control: Control<ArticleFormData>;
  className?: string;
}

const MetaForm: FC<Props> = (props) => {
  const { control, className } = props;
  const { t } = useArticlesTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('meta')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue={false}
              control={control}
              name='enabled'
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <FormGroup
                  row
                  help={t('determine_if_article_is_visible')}
                  error={error?.message}
                  label={t('enabled')}
                >
                  <Form.Switch isInvalid={!!error} checked={value} {...rest} />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col>
            <Controller
              control={control}
              name='published'
              render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('published')}>
                  <DatePicker value={value} {...rest} />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='author'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('author')}>
                  <Form.Control isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MetaForm;
