import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './hoc';

const AuthenticatedLayout: FC = () => {
  return (
    <>
      <Header />
      <main className='main pb-5'>
        <Outlet />
      </main>
    </>
  );
};

export default AuthenticatedLayout;
