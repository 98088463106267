import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import { reducer as magazinesReducer } from 'features/magazines/slice';
import { reducer as authReducer } from 'features/auth/slice';
import { reducer as articlesReducer } from 'features/articles/slice';
import { reducer as promoCodesReducer } from 'features/promo-codes/slice';
import { reducer as usersReducer } from 'features/users/slice';
import { reducer as subscriptionsReducer } from 'features/subscriptions/slice';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  magazines: magazinesReducer,
  auth: authReducer,
  articles: articlesReducer,
  promoCodes: promoCodesReducer,
  users: usersReducer,
  subscriptions: subscriptionsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (get) => get({ serializableCheck: { ignoredActions } }).concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
