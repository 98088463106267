import React, { FC, useCallback, useMemo } from 'react';
import { useAppSelector } from 'core/redux-store';
import { selectFilter, selectPaging, selectSort } from '../selectors';
import { useSubscriptions, useSubscriptionsTranslations } from '../hooks';
import { BoldCell, DataTable, UpdatedCell } from 'components';
import { Column } from 'react-table';
import { Subscription } from '../types';
import { useDispatch } from 'react-redux';
import { Paging, Sort } from 'api/types';
import { setPaging, setSort } from '../slice';
import { PriceCell, StatusCell, ActionsCell } from '../components';

const SubscriptionsTable: FC = () => {
  const { t } = useSubscriptionsTranslations();
  const dispatch = useDispatch();

  const filter = useAppSelector(selectFilter);
  const sort = useAppSelector(selectSort);
  const paging = useAppSelector(selectPaging);

  const { subscriptionsCount, subscriptions } = useSubscriptions({ filter, sort, paging });

  const handleOnPagingChange = useCallback(
    (newPaging: Paging) => dispatch(setPaging({ paging: newPaging })),
    [dispatch]
  );

  const handleOnSortChange = useCallback(
    (newSort: Sort) => dispatch(setSort({ sort: newSort })),
    [dispatch]
  );

  const columns = useMemo<Column<Subscription>[]>(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('title'),
        Cell: BoldCell,
        accessor: 'title',
      },
      {
        Header: t('price'),
        Cell: PriceCell,
        accessor: 'price',
      },
      {
        Header: t('status'),
        Cell: StatusCell,
        accessor: 'enabled',
      },
      {
        Header: t('duration_in_months'),
        accessor: 'durationInMonths',
      },
      {
        Header: t('updated'),
        Cell: UpdatedCell,
        accessor: 'updated',
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
      {
        Header: t('actions'),
        id: 'actions',
        disableSortBy: true,
        Cell: ActionsCell,
      },
    ],
    [t]
  );

  return (
    <DataTable
      data={subscriptions}
      columns={columns}
      total={subscriptionsCount}
      onPagingChange={handleOnPagingChange}
      onSortChange={handleOnSortChange}
      paging={paging}
      sort={sort}
    />
  );
};

export default SubscriptionsTable;
