import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataResponse, ListResponse, Paging, Sort } from 'api/types';
import { PromoCode } from './types';
import { PromoCodesFilter } from 'api/clients/promo-codes/types';
import { logout } from 'features/auth/slice';

interface State {
  promoCodes: PromoCode[];
  promoCodesCount: number;
  lastUpdated: number;
  filter: PromoCodesFilter;
  sort: Sort;
  paging: Paging;
  promoCode: PromoCode | null;
}

const initialState: State = {
  promoCodes: [],
  promoCodesCount: 0,
  lastUpdated: Date.now(),
  filter: {},
  sort: { sortBy: 'created', sortDirection: 'desc' },
  paging: { limit: 10, offset: 0 },
  promoCode: null,
};

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {
    setPaging: (state, action: PayloadAction<{ paging: Paging }>) => {
      state.paging = action.payload.paging;
    },
    setSort: (state, action: PayloadAction<{ sort: Sort }>) => {
      state.sort = action.payload.sort;
      state.paging = { ...state.paging, offset: 0 };
    },
    setFilter: (state, action: PayloadAction<{ filter: PromoCodesFilter }>) => {
      state.filter = action.payload.filter;
      state.paging = { ...state.paging, offset: 0 };
    },
    setPromoCodes: (state, action: PayloadAction<ListResponse<PromoCode>>) => {
      state.promoCodes = action.payload.data;
      state.promoCodesCount = action.payload.total;
    },
    updatePromoCodes: (state) => {
      state.lastUpdated = Date.now();
    },
    setPromoCode: (state, action: PayloadAction<DataResponse<PromoCode>>) => {
      state.promoCode = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setPromoCodes, updatePromoCodes, setPromoCode, setSort, setPaging, setFilter } =
  promoCodesSlice.actions;

export const { reducer } = promoCodesSlice;
