import { FC } from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';

const UpdatedCell: FC<CellProps<any, string | null>> = (props) => {
  const { value: notified } = props;
  const { t } = useTranslation('common');

  if (!notified) return t('not_notified');
  return <>{notified}</>;
};

export default UpdatedCell;
