import { PromoCode as ApiPromoCode, SavePromoCodeRequest } from 'api/clients/promo-codes/types';
import { PromoCode, PromoCodeFormData } from './types';
import moment from 'moment';
import { formatDate, parseDate } from 'utils/date';

export const mapPromoCode = (promoCode: ApiPromoCode): PromoCode => ({
  ...promoCode,
  updated: promoCode.updated ? moment(promoCode.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  startDate: moment(promoCode.startDate).format('YYYY-MM-DD'),
  endDate: moment(promoCode.endDate).format('YYYY-MM-DD'),
  created: moment(promoCode.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapPromoCodeFormData = (promoCode: PromoCode): PromoCodeFormData => ({
  enabled: promoCode.enabled,
  code: promoCode.code,
  startDate: parseDate(promoCode.startDate),
  endDate: parseDate(promoCode.endDate),
  numberOfCodes: promoCode.numberOfCodes,
  sku: promoCode.magazine?.sku ?? promoCode.subscription?.sku,
});

export const mapSavePromoCodeRequest = (formData: PromoCodeFormData): SavePromoCodeRequest => ({
  ...formData,
  startDate: formatDate(formData.startDate),
  endDate: formatDate(formData.endDate),
});
