import React, { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import classNames from 'classnames';

interface Props {
  className?: string;
  value: Date | null;
  onChange: (value: Date | null) => void;
  onBlur?: () => void;
  isInvalid?: boolean;
}

const DatePicker: FC<Props> = (props) => {
  const { className, value, onChange, onBlur } = props;

  return (
    <ReactDatePicker
      className={classNames('form-control', className)}
      dateFormat='yyyy-MM-dd'
      onBlur={onBlur}
      selected={value}
      onChange={onChange}
    />
  );
};

export default DatePicker;
