import { MagazinesFilter } from 'api/clients/magazines/types';
import { Paging, Sort } from 'api/types';
import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { selectPromoCodes, selectPromoCodesCount, selectPromoCodesLastUpdated } from '../selectors';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { PromoCodesClient } from 'api/clients';
import { setPromoCodes } from '../slice';
import { mapPromoCode } from '../map';
import { PromoCodesFilter } from 'api/clients/promo-codes/types';

export const getQueryKey = (filter: PromoCodesFilter, sort: Sort, paging: Paging) => {
  return ['promo-codes', filter, sort, paging];
};

interface Props {
  filter: PromoCodesFilter;
  sort: Sort;
  paging: Paging;
}

const usePromoCodes = (props: Props) => {
  const { filter, sort, paging } = props;

  const dispatch = useAppDispatch();
  const lastUpdated = useAppSelector(selectPromoCodesLastUpdated);
  const promoCodes = useAppSelector(selectPromoCodes);
  const promoCodesCount = useAppSelector(selectPromoCodesCount);

  const getPromoCodesFn = () => PromoCodesClient.getPromoCodes({ filter, paging, sort });
  const { isLoading, refetch } = useQuery(getQueryKey(filter, sort, paging), getPromoCodesFn, {
    onSuccess: (response) => {
      const data = response.data.map(mapPromoCode);
      dispatch(setPromoCodes({ ...response, data }));
    },
  });

  useEffect(() => {
    refetch();
  }, [lastUpdated, refetch]);

  return { isLoading, promoCodes, promoCodesCount };
};

export default usePromoCodes;
