import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { selectPromoCode } from '../selectors';
import { useQuery } from 'react-query';
import { PromoCodesClient } from 'api/clients';
import { setPromoCode } from '../slice';
import { mapPromoCode } from '../map';

export const getQueryKey = (id: number) => {
  return ['promo-code', id];
};

const usePromoCode = (id: number) => {
  const dispatch = useAppDispatch();
  const promoCode = useAppSelector(selectPromoCode);

  const getPromoCodeFn = () => PromoCodesClient.getPromoCode(id);
  const { isLoading } = useQuery(getQueryKey(id), getPromoCodeFn, {
    onSuccess: (response) => {
      const data = mapPromoCode(response.data);
      dispatch(setPromoCode({ data }));
    },
  });

  return { isLoading, promoCode };
};

export default usePromoCode;
