import React, { FC } from 'react';
import { Pagination as ReactPagination } from 'react-bootstrap';
import classNames from 'classnames';
import { Paging } from '../api/types';
import { useTranslation } from 'react-i18next';
import range from 'lodash/range';

export interface PaginationProps {
  className?: string;
  total: number;
  paging: Paging;
  onPagingChange: (paging: Paging) => void;
}

const Pagination: FC<PaginationProps> = (props) => {
  const { className, total, paging, onPagingChange } = props;
  const { t } = useTranslation('pagination');

  const currentPage = paging.offset / paging.limit;
  const pagesCount = Math.ceil(total / paging.limit);
  const pages = range(Math.max(0, currentPage - 4), Math.min(currentPage + 5, pagesCount));

  const gotToPage = (page: number) => {
    onPagingChange({ ...paging, offset: page * paging.limit });
  };

  return (
    <div
      className={classNames(
        'row justify-content-center justify-content-sm-between align-items-sm-center',
        className
      )}
    >
      <div className='col-sm mb-2 mb-sm-0'>
        <div className='d-flex justify-content-center justify-content-sm-start align-items-center'>
          <span className='me-2'>{`${t('showing')}:`}</span>
          <span className='me-2'>{Math.min(paging.limit, total)}</span>
          <span className='text-secondary me-2'>{t('of')}</span>
          <span>{total}</span>
        </div>
      </div>
      <div className='col-sm-auto'>
        <ReactPagination className='mb-0'>
          <ReactPagination.First onClick={() => gotToPage(0)} disabled={currentPage === 0}>
            {t('first')}
          </ReactPagination.First>
          <ReactPagination.Prev
            onClick={() => gotToPage(currentPage - 1)}
            disabled={currentPage === 0}
          >
            {t('prev')}
          </ReactPagination.Prev>
          {pages.map((page) => (
            <ReactPagination.Item
              key={page}
              onClick={() => gotToPage(page)}
              active={page === currentPage}
            >
              {page + 1}
            </ReactPagination.Item>
          ))}
          <ReactPagination.Next
            onClick={() => gotToPage(currentPage + 1)}
            disabled={currentPage + 1 >= pagesCount}
          >
            {t('next')}
          </ReactPagination.Next>
          <ReactPagination.Last
            onClick={() => gotToPage(pagesCount - 1)}
            disabled={currentPage === pagesCount - 1}
          >
            {t('last')}
          </ReactPagination.Last>
        </ReactPagination>
      </div>
    </div>
  );
};

export default Pagination;
