import { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useLogout } from 'features/auth/hooks';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'core/redux-store';
import { selectProfile } from 'features/auth/selectors';

const UserDropdown: FC = () => {
  const { logout } = useLogout();
  const { t } = useTranslation('profile');
  const profile = useAppSelector(selectProfile);

  if (!profile) return <></>;

  const { roles, email } = profile;
  const [role] = roles;

  return (
    <Dropdown>
      <Dropdown.Toggle as='span' className='navbar-dropdown-account-wrapper no-arrow'>
        <div className='avatar avatar-sm avatar-soft-primary avatar-circle'>
          <span className='avatar-initials'>{email[0]}</span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        align='end'
        className='navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account'
      >
        <Dropdown.Item>
          <div className='d-flex align-items-center'>
            <div className='avatar avatar-sm avatar-soft-primary avatar-circle'>
              <span className='avatar-initials'>{email[0]}</span>
            </div>
            <div className='flex-grow-1 ms-3'>
              <h5 className='mb-0'>{email}</h5>
              <p className='card-text text-body'>{t(role.toLowerCase())}</p>
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={logout}>{t('sign_out')}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserDropdown;
