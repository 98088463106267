import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

export type LegendIndicatorVariant = 'success' | 'danger' | 'primary' | 'secondary';

interface Props {
  className?: string;
  label: string;
  variant?: LegendIndicatorVariant;
}

const indicatorColor: Record<LegendIndicatorVariant, string> = {
  primary: 'bg-primary',
  success: 'bg-success',
  danger: 'bg-danger',
  secondary: 'bg-secondary',
};

const LegendIndicator: FC<Props> = (props) => {
  const { className, label, variant = 'primary' } = props;
  const indicatorClassName = useMemo<string>(() => indicatorColor[variant], [variant]);

  return (
    <div className={className}>
      <span className={classNames('legend-indicator', indicatorClassName)} />
      {label}
    </div>
  );
};

export default LegendIndicator;
