import { toast } from 'react-toastify';
import i18n from 'i18next';

export const showErrorToast = (content: string) => {
  toast.error(content);
};

export const showBaseErrorToast = () => {
  const content = i18n.t('errors.an_error_has_occurred');
  toast.error(content);
};

export const showSuccessToast = (content: string) => {
  toast.success(content);
};
