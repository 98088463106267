import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { PromoCodeForm } from 'features/promo-codes/hoc';

const PromoCodeAddPage: FC = () => {
  return (
    <Container>
      <PromoCodeForm />
    </Container>
  );
};

export default PromoCodeAddPage;
