import React, { FC, useCallback, useMemo } from 'react';
import { useAppSelector } from 'core/redux-store';
import { selectFilter, selectPaging, selectSort } from '../selectors';
import { useArticles, useArticlesTranslations } from '../hooks';
import { DataTable, NotifiedCell } from 'components';
import { Column } from 'react-table';
import { Article } from '../types';
import { useDispatch } from 'react-redux';
import { Paging, Sort } from 'api/types';
import { setPaging, setSort } from '../slice';
import { StatusCell, ActionsCell, TitleCell } from '../components';

const ArticlesTable: FC = () => {
  const { t } = useArticlesTranslations();
  const dispatch = useDispatch();

  const filter = useAppSelector(selectFilter);
  const sort = useAppSelector(selectSort);
  const paging = useAppSelector(selectPaging);

  const { articlesCount, articles } = useArticles({ filter, sort, paging });

  const handleOnPagingChange = useCallback(
    (newPaging: Paging) => dispatch(setPaging({ paging: newPaging })),
    [dispatch]
  );

  const handleOnSortChange = useCallback(
    (newSort: Sort) => dispatch(setSort({ sort: newSort })),
    [dispatch]
  );

  const columns = useMemo<Column<Article>[]>(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('title'),
        Cell: TitleCell,
        accessor: 'title',
      },
      {
        Header: t('author'),
        accessor: 'author',
      },
      {
        Header: t('notified'),
        Cell: NotifiedCell,
        accessor: 'notified',
      },
      {
        Header: t('published'),
        accessor: 'published',
      },
      {
        Header: t('status'),
        accessor: 'enabled',
        Cell: StatusCell,
      },
      {
        Header: t('created'),
        accessor: 'created',
      },
      {
        Header: t('actions'),
        id: 'actions',
        disableSortBy: true,
        Cell: ActionsCell,
      },
    ],
    [t]
  );

  return (
    <DataTable
      data={articles}
      columns={columns}
      total={articlesCount}
      onPagingChange={handleOnPagingChange}
      onSortChange={handleOnSortChange}
      paging={paging}
      sort={sort}
    />
  );
};

export default ArticlesTable;
