import React, { FC } from 'react';
import { PaginationProps } from './Pagination';
import Table, { TableProps } from './Table';
import Pagination from './Pagination';

type Props = Omit<PaginationProps, 'className'> &
  Omit<TableProps, 'className'> & {
    className?: string;
  };

const DataTable: FC<Props> = (props) => {
  const { className, data, columns, onRowClick, onSortChange, sort, ...rest } = props;

  return (
    <div className={className}>
      <Table
        sort={sort}
        onSortChange={onSortChange}
        onRowClick={onRowClick}
        data={data}
        columns={columns}
      />
      <div className='card-footer'>
        <Pagination {...rest} />
      </div>
    </div>
  );
};

export default DataTable;
