export type CurrencyCode = 'eur' | 'usd';

export interface Currency {
  label: string;
  code: CurrencyCode;
}

const currencies: Record<CurrencyCode, string> = {
  eur: 'EUR',
  usd: 'USD',
};

export default currencies;
