import React, { FC, useMemo } from 'react';
import { LegendIndicatorVariant } from 'components/LegendIndicator';
import { LegendIndicator } from 'components';
import { CellProps } from 'react-table';
import { PromoCode } from '../types';
import { usePromoCodesTranslations } from '../hooks';

interface MemoValue {
  variant: LegendIndicatorVariant;
  label: string;
}

const StatusCell: FC<CellProps<PromoCode, boolean>> = (props) => {
  const { value: enabled } = props;
  const { t } = usePromoCodesTranslations();

  const { variant, label } = useMemo<MemoValue>(
    () => ({
      variant: enabled ? 'success' : 'secondary',
      label: enabled ? t('enabled') : t('disabled'),
    }),
    [enabled, t]
  );

  return <LegendIndicator variant={variant} label={label} />;
};

export default StatusCell;
