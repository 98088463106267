import { DataResponse, ListRequest, ListResponse } from '../../types';
import { Subscription, SubscriptionsFilter, SaveSubscriptionRequest } from './types';
import httpClient from '../../httpClient';

const baseUrl = '/admin/subscriptions';

export const getSubscriptions = async (request: ListRequest<SubscriptionsFilter>) => {
  const params = { ...request.filter, ...request.sort, ...request.paging };
  return httpClient.get<ListRequest<SubscriptionsFilter>, ListResponse<Subscription>>(baseUrl, {
    params,
  });
};

export const getSubscription = async (id: number) => {
  return httpClient.get<void, DataResponse<Subscription>>(`${baseUrl}/${id}`);
};

export const addSubscription = async (request: SaveSubscriptionRequest) => {
  return httpClient.post<SaveSubscriptionRequest, DataResponse<Subscription>>(baseUrl, request);
};

export const editSubscription = async (id: number, request: SaveSubscriptionRequest) => {
  return httpClient.put<SaveSubscriptionRequest, DataResponse<Subscription>>(
    `${baseUrl}/${id}`,
    request
  );
};
