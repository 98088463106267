import { useAppDispatch, useAppSelector } from 'core/redux-store';
import { selectSubscription } from '../selectors';
import { useQuery } from 'react-query';
import { SubscriptionsClient } from 'api/clients';
import { setSubscription } from '../slice';
import { mapSubscription } from '../map';

export const getQueryKey = (id: number) => {
  return ['subscription', id];
};

const useSubscription = (id: number) => {
  const dispatch = useAppDispatch();
  const subscription = useAppSelector(selectSubscription);

  const getSubscriptionFn = () => SubscriptionsClient.getSubscription(id);
  const { isLoading } = useQuery(getQueryKey(id), getSubscriptionFn, {
    onSuccess: (response) => {
      const data = mapSubscription(response.data);
      dispatch(setSubscription({ data }));
    },
  });

  return { isLoading, subscription };
};

export default useSubscription;
