import { Article, ArticleFormData } from '../types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { ArticlesClient } from 'api/clients';
import { useEffect } from 'react';
import { mapArticleFormData, mapSaveArticleRequest } from '../map';
import { updateArticles } from '../slice';
import { useAppDispatch } from 'core/redux-store';

const photoShape = yup.object().shape({
  name: yup.string().required(),
  size: yup.number().required(),
  value: yup.string().required(),
});

const articleShape = yup.object().shape({
  enabled: yup.boolean().required(),
  title: yup.string().required(),
  description: yup.string().required(),
  url: yup.string().url().required(),
  author: yup.string().required(),
  published: yup.date().required(),
  addedPhoto: photoShape.nullable(),
});

const getSchema = () => articleShape.required();

interface Props {
  article?: Article;
  onSuccess: () => void;
}

const useArticleForm = (props: Props) => {
  const { article, onSuccess } = props;
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset } = useForm<ArticleFormData>({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    if (!article) return;
    reset(mapArticleFormData(article));
  }, [article, reset]);

  const { mutateAsync, isLoading } = useMutation(
    async (formData: ArticleFormData) => {
      const request = mapSaveArticleRequest(formData);
      if (!article) await ArticlesClient.addArticle(request);
      else await ArticlesClient.editArticle(article.id, request);
    },
    {
      onSuccess: () => {
        dispatch(updateArticles());
      },
    }
  );

  const save = async (formData: ArticleFormData) => {
    await mutateAsync(formData);
    onSuccess();
  };

  return { handleSubmit, save, control, isLoading };
};

export default useArticleForm;
