import { Magazine as ApiMagazine, SaveMagazineRequest } from 'api/clients/magazines/types';
import { Magazine, MagazineFormData } from './types';
import moment from 'moment';
import { formatDate, parseDate } from '../../utils/date';

export const mapMagazine = (magazine: ApiMagazine): Magazine => ({
  ...magazine,
  updated: magazine.updated ? moment(magazine.updated).format('YYYY-MM-DD HH:mm:ss') : null,
  published: moment(magazine.published).format('YYYY-MM-DD'),
  notified: magazine.notified ? moment(magazine.notified).format('YYYY-MM-DD HH:mm:ss') : null,
  created: moment(magazine.created).format('YYYY-MM-DD HH:mm:ss'),
});

export const mapMagazineFormData = (magazine: Magazine): MagazineFormData => ({
  title: magazine.title,
  sku: magazine.sku,
  description: magazine.description,
  price: magazine.price,
  currency: magazine.currency,
  link: magazine.link,
  enabled: magazine.enabled,
  published: parseDate(magazine.published),
  addedPhotos: [],
  removedPhotos: [],
});

export const mapSaveMagazineRequest = (formData: MagazineFormData): SaveMagazineRequest => ({
  ...formData,
  published: formatDate(formData.published),
  addedPhotos: formData.addedPhotos.map((addedPhoto) => addedPhoto.value),
});
