import { DataResponse, ListRequest, ListResponse } from '../../types';
import { Magazine, MagazinesFilter, SaveMagazineRequest } from './types';
import httpClient from '../../httpClient';

const baseUrl = '/admin/magazines';

export const getMagazines = async (request: ListRequest<MagazinesFilter>) => {
  const params = { ...request.filter, ...request.sort, ...request.paging };
  return httpClient.get<ListRequest<MagazinesFilter>, ListResponse<Magazine>>(baseUrl, { params });
};

export const getMagazine = async (id: number) => {
  return httpClient.get<void, DataResponse<Magazine>>(`${baseUrl}/${id}`);
};

export const addMagazine = async (request: SaveMagazineRequest) => {
  return httpClient.post<SaveMagazineRequest, DataResponse<Magazine>>(baseUrl, request);
};

export const editMagazine = async (id: number, request: SaveMagazineRequest) => {
  return httpClient.put<SaveMagazineRequest, DataResponse<Magazine>>(`${baseUrl}/${id}`, request);
};

export const notifyMagazine = async (id: number) => {
  return httpClient.put<void, DataResponse<Magazine>>(`${baseUrl}/${id}`);
};
