import React, { FC } from 'react';
import { PromoCodesSearch, PromoCodesTable } from 'features/promo-codes/hoc';
import { usePromoCodesTranslations } from 'features/promo-codes/hooks';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PromoCodesPage: FC = () => {
  const { t } = usePromoCodesTranslations();
  const navigate = useNavigate();

  return (
    <Container>
      <div className='page-header'>
        <h2 className='page-header-title'>{t('promo_codes')}</h2>
      </div>
      <Row className='mb-3'>
        <Col lg='auto'>
          <PromoCodesSearch />
        </Col>
        <Col className='d-flex justify-content-end'>
          <Button onClick={() => navigate('add')} size='sm' variant='primary'>
            {t('add_promo_code')}
          </Button>
        </Col>
      </Row>
      <Card>
        <PromoCodesTable />
      </Card>
    </Container>
  );
};

export default PromoCodesPage;
