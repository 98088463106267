import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { usePromoCode } from 'features/promo-codes/hooks';
import { PromoCodeForm } from 'features/promo-codes/hoc';
import { useParams } from 'react-router-dom';

const PromoCodeEditPage: FC = () => {
  const params = useParams();
  const id = Number(params.id);
  const { promoCode, isLoading } = usePromoCode(id);

  return (
    <Container>{!!promoCode && !isLoading && <PromoCodeForm promoCode={promoCode} />}</Container>
  );
};

export default PromoCodeEditPage;
