import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { MagazineForm } from 'features/magazines/hoc';

const MagazineAddPage: FC = () => {
  return (
    <Container>
      <MagazineForm />
    </Container>
  );
};

export default MagazineAddPage;
