import React, { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {
  ArticleAddPage,
  ArticleEditPage,
  ArticlesPage,
  LoginPage,
  MagazineAddPage,
  MagazineEditPage,
  MagazinesPage,
  PromoCodeAddPage,
  PromoCodeEditPage,
  PromoCodesPage,
  SubscriptionAddPage,
  SubscriptionEditPage,
  SubscriptionsPage,
  UsersPage,
} from 'pages';
import RequireAuth from './RequireAuth';
import { useProfile } from 'features/auth/hooks';
import { AuthenticatedLayout } from 'layouts';

const RootNavigator: FC = () => {
  useProfile();

  return (
    <main className='main'>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/' element={<AuthenticatedLayout />}>
            <Route
              path='/magazines'
              element={
                <RequireAuth>
                  <MagazinesPage />
                </RequireAuth>
              }
            />
            <Route
              path='/magazines/add'
              element={
                <RequireAuth>
                  <MagazineAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/magazines/edit/:id'
              element={
                <RequireAuth>
                  <MagazineEditPage />
                </RequireAuth>
              }
            />
            <Route
              path='/subscriptions'
              element={
                <RequireAuth>
                  <SubscriptionsPage />
                </RequireAuth>
              }
            />
            <Route
              path='/subscriptions/add'
              element={
                <RequireAuth>
                  <SubscriptionAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/subscriptions/edit/:id'
              element={
                <RequireAuth>
                  <SubscriptionEditPage />
                </RequireAuth>
              }
            />
            <Route
              path='/users'
              element={
                <RequireAuth>
                  <UsersPage />
                </RequireAuth>
              }
            />
            <Route
              path='/articles'
              element={
                <RequireAuth>
                  <ArticlesPage />
                </RequireAuth>
              }
            />
            <Route
              path='/articles/add'
              element={
                <RequireAuth>
                  <ArticleAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/articles/edit/:id'
              element={
                <RequireAuth>
                  <ArticleEditPage />
                </RequireAuth>
              }
            />
            <Route
              path='/promo-codes'
              element={
                <RequireAuth>
                  <PromoCodesPage />
                </RequireAuth>
              }
            />
            <Route
              path='/promo-codes/add'
              element={
                <RequireAuth>
                  <PromoCodeAddPage />
                </RequireAuth>
              }
            />
            <Route
              path='/promo-codes/edit/:id'
              element={
                <RequireAuth>
                  <PromoCodeEditPage />
                </RequireAuth>
              }
            />
            <Route path='/' element={<Navigate replace to='/articles' />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </main>
  );
};

export default RootNavigator;
