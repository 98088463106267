import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataResponse, ListResponse, Paging, Sort } from 'api/types';
import { Subscription } from './types';
import { SubscriptionsFilter } from 'api/clients/subscriptions/types';
import { logout } from 'features/auth/slice';

interface State {
  subscriptions: Subscription[];
  subscriptionsCount: number;
  lastUpdated: number;
  filter: SubscriptionsFilter;
  sort: Sort;
  paging: Paging;
  subscription: Subscription | null;
}

const initialState: State = {
  subscriptions: [],
  subscriptionsCount: 0,
  lastUpdated: Date.now(),
  filter: {},
  sort: { sortBy: 'created', sortDirection: 'desc' },
  paging: { limit: 10, offset: 0 },
  subscription: null,
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setPaging: (state, action: PayloadAction<{ paging: Paging }>) => {
      state.paging = action.payload.paging;
    },
    setSort: (state, action: PayloadAction<{ sort: Sort }>) => {
      state.sort = action.payload.sort;
      state.paging = { ...state.paging, offset: 0 };
    },
    setFilter: (state, action: PayloadAction<{ filter: SubscriptionsFilter }>) => {
      state.filter = action.payload.filter;
      state.paging = { ...state.paging, offset: 0 };
    },
    setSubscriptions: (state, action: PayloadAction<ListResponse<Subscription>>) => {
      state.subscriptions = action.payload.data;
      state.subscriptionsCount = action.payload.total;
    },
    updateSubscriptions: (state) => {
      state.lastUpdated = Date.now();
    },
    setSubscription: (state, action: PayloadAction<DataResponse<Subscription>>) => {
      state.subscription = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  setSubscriptions,
  updateSubscriptions,
  setSubscription,
  setSort,
  setPaging,
  setFilter,
} = subscriptionsSlice.actions;

export const { reducer } = subscriptionsSlice;
