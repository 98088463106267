import { useMutation } from 'react-query';
import { ArticlesClient } from 'api/clients';
import { mapArticle } from '../map';
import { setArticle, updateArticles } from '../slice';
import { useAppDispatch } from 'core/redux-store';

const useNotifyMagazine = () => {
  const dispatch = useAppDispatch();

  const { mutateAsync, isLoading } = useMutation(
    async (id: number) => ArticlesClient.notifyArticle(id),
    {
      onSuccess: (response) => {
        const data = mapArticle(response.data);
        dispatch(setArticle({ data }));
        dispatch(updateArticles());
      },
    }
  );

  const notify = (id: number) => mutateAsync(id);

  return { isLoading, notify };
};

export default useNotifyMagazine;
