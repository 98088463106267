import React, { FC, useMemo } from 'react';
import { LegendIndicatorVariant } from 'components/LegendIndicator';
import { LegendIndicator } from 'components';
import { CellProps } from 'react-table';
import { User } from '../types';
import { useUsersTranslations } from '../hooks';

interface MemoValue {
  variant: LegendIndicatorVariant;
  label: string;
}

const IsSubscriberCell: FC<CellProps<User, boolean>> = (props) => {
  const { value: isSubscriber } = props;
  const { t } = useUsersTranslations();

  const { variant, label } = useMemo<MemoValue>(
    () => ({
      variant: isSubscriber ? 'success' : 'secondary',
      label: isSubscriber ? t('subscriber') : t('not_subscriber'),
    }),
    [isSubscriber, t]
  );

  return <LegendIndicator variant={variant} label={label} />;
};

export default IsSubscriberCell;
