import { FC } from 'react';
import { CellProps } from 'react-table';

const BoldCell: FC<CellProps<any, string>> = (props) => {
  const { value } = props;

  return <span className='d-block h5 text-inherit mb-0'>{value}</span>;
};

export default BoldCell;
