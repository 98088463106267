import { Control, Controller } from 'react-hook-form';
import { Article, ArticleFormData } from '../types';
import React, { FC } from 'react';
import { useArticlesTranslations } from '../hooks';
import { Card } from 'react-bootstrap';
import { FileCard } from 'components';
import PhotoControl from './PhotoControl';
import { getAbsoluteFilePath } from '../utils';

interface Props {
  className?: string;
  article?: Article;
  control: Control<ArticleFormData>;
}

const MediaForm: FC<Props> = (props) => {
  const { control, className, article } = props;
  const { t } = useArticlesTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('media')}</h5>
      </Card.Header>
      <Card.Body>
        {!!article && (
          <FileCard
            className='mb-2'
            name={article.photo.name}
            src={getAbsoluteFilePath(article.photo.name)}
            size={article.photo.size}
          />
        )}
        <Controller
          defaultValue={null}
          control={control}
          name='addedPhoto'
          render={({ field: { onChange, value } }) => (
            <PhotoControl photo={value} onPhotoChange={onChange} />
          )}
        />
      </Card.Body>
    </Card>
  );
};

export default MediaForm;
