import React, { FC } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useSubscriptionForm, useSubscriptionsTranslations } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { SubscriptionInformationForm, MetaForm } from '../components';
import { Subscription } from '../types';
import { showSuccessToast } from 'core/toast';

interface Props {
  subscription?: Subscription;
}

const SubscriptionForm: FC<Props> = (props) => {
  const { subscription } = props;
  const { t } = useSubscriptionsTranslations();
  const navigate = useNavigate();

  const onSuccess = () => {
    showSuccessToast(t('subscription_is_successfully_saved'));
    navigate('/subscriptions');
  };

  const { control, handleSubmit, save, isLoading } = useSubscriptionForm({
    subscription,
    onSuccess,
  });

  return (
    <>
      <div className='page-header'>
        <Row className='align-items-center'>
          <Col>
            <h2 className='page-header-title'>
              {!!subscription ? t('edit_subscription') : t('add_subscription')}
            </h2>
          </Col>
          <Col lg='auto'>
            <Button
              size='sm'
              className='me-2'
              onClick={() => navigate('/subscriptions')}
              disabled={isLoading}
              variant='white'
            >
              {t('back_to_subscriptions_list')}
            </Button>
            <Button size='sm' onClick={handleSubmit(save)} disabled={isLoading} variant='primary'>
              {t('save_subscription')}
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={8}>
          <SubscriptionInformationForm control={control} />
        </Col>
        <Col lg={4}>
          <MetaForm control={control} />
        </Col>
      </Row>
    </>
  );
};

export default SubscriptionForm;
