import { RootState } from 'core/redux-store';
import { Article } from './types';
import { ArticlesFilter } from 'api/clients/articles/types';
import { Paging, Sort } from 'api/types';

export const selectArticles = (state: RootState): Article[] => {
  return state.articles.articles;
};

export const selectArticlesCount = (state: RootState): number => {
  return state.articles.articlesCount;
};

export const selectArticlesLastUpdated = (state: RootState): number => {
  return state.articles.lastUpdated;
};

export const selectFilter = (state: RootState): ArticlesFilter => {
  return state.articles.filter;
};

export const selectPaging = (state: RootState): Paging => {
  return state.articles.paging;
};

export const selectSort = (state: RootState): Sort => {
  return state.articles.sort;
};

export const selectArticle = (state: RootState): Article | null => {
  return state.articles.article;
};
