export const fileToBase64String = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const sizeToKb = (sizeInBytes: number): string => {
  return (sizeInBytes / 1024).toFixed(2);
};
