import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { PromoCodeFormData } from '../types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { usePromoCodesTranslations } from '../hooks';
import { DatePicker, FormGroup } from 'components';

interface Props {
  className?: string;
  control: Control<PromoCodeFormData>;
}

const PromoCodeInformationForm: FC<Props> = (props) => {
  const { control, className } = props;
  const { t } = usePromoCodesTranslations();

  return (
    <Card className={className}>
      <Card.Header>
        <h5 className='card-header-title'>{t('promo_code_information')}</h5>
      </Card.Header>
      <Card.Body>
        <Row className='mb-4'>
          <Col>
            <Controller
              defaultValue=''
              control={control}
              name='code'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('code')}>
                  <Form.Control isInvalid={!!error} {...field} type='text' />
                </FormGroup>
              )}
            />
          </Col>
          <Col>
            <Controller
              defaultValue={0}
              control={control}
              name='numberOfCodes'
              render={({ field, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('number_of_codes')}>
                  <Form.Control isInvalid={!!error} {...field} type='number' />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              control={control}
              name='startDate'
              render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('start_date')}>
                  <DatePicker value={value} {...rest} />
                </FormGroup>
              )}
            />
          </Col>
          <Col>
            <Controller
              control={control}
              name='endDate'
              render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
                <FormGroup error={error?.message} label={t('end_date')}>
                  <DatePicker value={value} {...rest} />
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PromoCodeInformationForm;
