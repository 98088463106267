import { FC } from 'react';
import { CellProps } from 'react-table';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Subscription } from '../types';

const PriceCell: FC<CellProps<Subscription, number>> = (props) => {
  const { value: price, row } = props;
  const currency = row.original.currency;

  return <>{`${price.toFixed(2)} ${getSymbolFromCurrency(currency)}`}</>;
};

export default PriceCell;
